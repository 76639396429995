import React, { memo } from 'react';
import { graphql } from 'gatsby';
import { MainPage } from '../templates/MainPage';
import { Helmet } from 'react-helmet';
import SeoText from 'components/SeoText';

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>Видеопродакшн Elandic в Москве студия полного цикла</title>
        <meta name="description" content="Видеопродакшн заказать в Москве цена от студии полного цикла Elandic. Заказать услуги видеопродакшена для бизнеса онлайн стоимость на сайте компании." />
        <meta name="keywords" content="видеопродакшн, москва, студия, заказать, полного цикла, компания .услуги, реклама, цена, для бизнеса, стоимость" />
      </Helmet>
      <MainPage>
        <SeoText title='Видеопродакшн Elandic'>
          <p> В эпоху цифровых технологий качественный видеоконтент стал ключевым инструментом для продвижения бизнеса. Аудитория требует не просто информации, но эмоций, историй, визуальных образов, которые западут в душу и останутся в памяти.</p>

          <p>Именно к таким результатам стремится видеопродакшн Elandic, находящийся в сердце Москвы. Наша студия полного цикла предлагает не просто услуги по созданию видеороликов – мы предоставляем мощный инструмент для преображения вашего бизнеса.</p>

          <p>Если вы решили заказать услуги видеопродакшена в Москве, то Elandic – это выбор тех, кто ценит качество, креативность и индивидуальный подход. Мы не просто компания, предоставляющая услуги видеосъемки, мы – команда талантливых профессионалов, способная воплотить любые ваши идеи в жизнь.</p>

          <p>Elandic специализируется на создании рекламных роликов, корпоративных видео, видео презентаций и другого контента для бизнеса. В нашем портфолио – проекты для известных брендов и начинающих стартапов. Мы внимательно слушаем каждого клиента и подходим к выполнению задачи с полным пониманием специфики его бизнеса.</p>

          <p>Видеопродакшн Elandic выделяется на рынке сочетанием разумной цены и высокого качества оказываемых услуг. Мы предлагаем полный цикл производства – от разработки концепции до постпродакшн. Заказав у нас видео, вы получаете не только продукт, но и подробную аналитику по его эффективности для вашего бизнеса.</p>

          <p>Выбирая студию полного цикла Elandic в Москве, вы делаете ставку на профессионализм, инновационные подходы в видеопродакшене и индивидуальное внимание к вашему проекту. Стоимость услуг в нашей компании оправдана высочайшим качеством результата, который преобразит ваше видение и поможет заявить о себе широкой аудитории. Elandic – это выбор тех, кто стремится к лидерству и готов использовать самые современные инструменты для достижения своих целей.</p>

        </SeoText>
      </MainPage>
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["mainPage", "common", "layout"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default memo(IndexPage);
